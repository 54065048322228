const getQueryString = (params) => {
  const keys = Object.keys(params)
  if (keys.length === 0) return ''
  const str = keys.reduce(
    (acc, current) =>
      params[current] ? acc + '&' + current + '=' + params[current] : acc,
    '',
  )
  return str !== '' ? `?${str.replace('&', '')}` : str.replace('&', '')
}

export default getQueryString
